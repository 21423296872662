<template>
  <div class="codeID">
    <van-nav-bar
      title="证件照"
      left-arrow
      fixed
      @click-left="goBack"
      @click-right="isDropdownMenu = !isDropdownMenu"
    >
      <template #right>
        <van-icon size="22" name="apps-o" />
      </template>
    </van-nav-bar>
    <div
      class="downMenuModal"
      v-if="isDropdownMenu"
      @click="isDropdownMenu = false"
    >
      <div v-if="isDropdownMenu" class="dropdownMenu">
        <div class="menuItem" @click="$router.push('/')">
          <span>首页</span>
        </div>
        <div class="menuItem" @click="$router.push('/xuanke')">
          <span>选课中心</span>
        </div>
        <div class="menuItem" @click="$router.push('/tiku')">
          <span>题库</span>
        </div>
        <div class="menuItem" @click="$router.push('/homeLiveList')">
          <span>直播</span>
        </div>
        <div class="menuItem" @click="$router.push('/learning')">
          <span>学习中心</span>
        </div>
        <div class="menuItem" @click="downApp">
          <span>下载APP</span>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="photo-box">
        <!-- <div class="title">保存后无水印</div> -->
        <img class="logo-box" :src="activeList.data.filenames[0].preview_url" />
      </div>

      <div class="desc">
        <p>规格详情</p>
        <div>
          <span>像素尺寸：</span>
          <span>{{ listData.width }}*{{ listData.height }}PX</span>
          <span style="margin-left: 20px">文件大小：</span>
          <span>{{ listData.fileSize }}</span>
        </div>
        <div>
          <span>背景色：</span>
          <span class="activeBg" :class="'activeBg' + radio_1"></span>
        </div>
      </div>

      <div class="type">
        <p class="title">选择支付方式</p>
        <van-radio-group v-model="radio">
          <div class="payment">
            <van-radio name="1">微信</van-radio>
          </div>
          <div class="divider" v-if="!this.isWeixin()"></div>
          <div class="payment" v-if="!this.isWeixin()">
            <van-radio name="2">支付宝</van-radio>
          </div>
        </van-radio-group>
      </div>

      <div class="submit">
        <div class="title">¥{{ this.listData.price }}</div>
        <div class="baoMingBtn" @click="submit">去支付</div>
      </div>
    </div>
    <div class="alipaysubmit" v-html="alipayfrom"></div>
  </div>
</template>

<script>
import { makePhotoOrder, h5Pay,downLoadFree } from "@/api/photo.js";
import {
  pay,
  getAgressment,
  liveorder,
  getOpenId,
  h5LivePay,
} from "@/api/order.js";
function getUrlKey(name) {
  //获取url 参数
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

function getCodeApi(state) {
  //获取code
  let urlNow = encodeURIComponent(window.location.href);
  let scope = "snsapi_base"; //snsapi_userinfo //静默授权 用户无感知
  //let appid = "wxaca9d08ae096883e";
  let appid = "wx7f4b3784986dcb47";
  let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
  window.location.replace(url);
}
export default {
  name: "payment",
  data() {
    return {
      isDropdownMenu: false,
      radio: "1",
      radio_1: "#FFFFFF",
      openId: "",
      orderNo: "",
      alipayfrom: null,
      activeList: {
        data: {
          filenames: [
            {
              preview_url: "",
            },
          ],
        },
      },
      code: null,
      listData: {
        price: "",
      },
    };
  },
  mounted() {
    
    this.init();
    let _that = this;
    //document.addEventListener('visibilitychange', this.handleEventListener)
    // document.addEventListener("visibilitychange", function (res) {
    //   // console.log("外部支付====----",localStorage.getItem("orderpayFlag"));
    //   console.log("外部支付=0000000000",res);
    //   _that.$router.push({
    //     path: "/downloadPhoto",
    //   });
    //   //需要的操作
    // });
  },
  destroyed () {
    //document.removeEventListener('visibilitychange', this.handleEventListener)
    //document.removeEventListener('visibilitychange'); 
  },
  created() {
    if (window.location.href.indexOf("code") > -1) {
      if (this.isWeixin()) {
        let that = this;
        that.code = getUrlKey("code");
        if (that.code) {
          let data = new FormData();
          data.append("code", that.code);
          data.append(
            "userId",
            JSON.parse(window.localStorage.getItem("userInfo")).data.id
          );
          getOpenId(data).then((res) => {
            localStorage.setItem('saenOpenId',res.data.data.openId);
            that.$set(that, "openId", res.data.data.openId);
            // that.openId =  res.data.openId;
          });
        }
      }
    } else {
      if (this.isWeixin()) {
        getCodeApi(123);
      }
      return;
    }
  },
  // // 路由监听
  // beforeRouteEnter(to, from, next) {
  //       next((vm) => {
  //           // vm相当于上面的this
  //           console.log("======",from.path);
  //           if (from.path.indexOf("/savePhoto") > -1) {
  //               sessionStorage.removeItem("orderInfo");
  //               sessionStorage.removeItem("addressFlag");
  //               sessionStorage.removeItem("couponFlag");
  //           }
  //           vm.from = from.path;
  //           vm.to = to.path;
  //           if (sessionStorage.getItem("orderpayFlag") == "1") {
  //             vm.gopath("/savePhoto");
  //           }
  //           //vm.fromPage = from.path;
  //       });
  // },
  methods: {
    handleEventListener(){
      // console.log("1====11111");
      //  this.$router.push({
      //   path: "/downloadPhoto",
      // });
    },
    downApp() {
      window.location.href = "https://www.saen.com/app/";
    },
    // 跳转路径
    gopath(path) {
      this.$router.push({ path: path });
    },
    isWeixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" ||
        ua.match(/_SQ_/i) == "_sq_"
      ) {
        return true;
      } else {
        return false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    activeFn(type) {
      this.active = type;
    },
    submit() {
      var that = this;
      localStorage.setItem("orderpayFlag", "2");
      let data = new FormData();
      data.append("width", this.listData.width);
      data.append("height", this.listData.height);
      data.append("identificationPhotoId", this.listData.id);
      data.append("source", "h5");
      data.append("bgColor", this.activeList.data.filenames[0].bg_color);
      // data.append("fileSize", this.listData.fileSize);
      data.append("payWay", this.radio); //支付方式支付方式 0-免费 1-微信 2-支付宝 3-网银 4-余额

      makePhotoOrder(data).then((res) => {
        console.log("Res====", res);

        this.orderNo = res.data.data.orderNo;
        localStorage.setItem("orderNo", res.data.data.orderNo);
        let dataobj = new FormData();
        dataobj.append("orderNo", res.data.data.orderNo);
        if (this.isWeixin()) {
          dataobj.append("payWeChatType", "1");
          dataobj.append("openid", this.openId);
        } else {
          dataobj.append("payWeChatType", "2");
        }
        h5Pay(dataobj).then((res) => {
          if (this.isWeixin()) {
            WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              {
                //appId: "wxaca9d08ae096883e",
                appId: "wx7f4b3784986dcb47",
                timeStamp: res.data.data.timeStamp,
                nonceStr: res.data.data.nonce_str,
                package: "prepay_id=" + res.data.data.prepay_id,
                signType: "MD5",
                paySign: res.data.data.paySign,
              },
              function (res) {
                console.log("-------", res);
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  that.$router.push({
                    path: "/downloadPhoto",
                    query:{
                      payType:"intPay"
                    }
                  });
                } else {
                  that.$toast("微信支付失败");
                }
              }
            );
          } else {
            this.payload(res);
          }
        });
      });
    },
    payload(res) {
      localStorage.setItem("orderpayFlag", "1");
      localStorage.setItem("serviceId", res.data.data.serviceId);
      if (this.radio == 1) {
        window.location.href = res.data.data.mweb_url+"&redirect_url="+encodeURIComponent("https://m.saen.com/downloadPhoto"); //
      } else { 
        this.alipayfrom = res.data.data.aliSign;
        this.$nextTick(() => {
          document.forms["punchout_form"].submit();
        });
      }
    },
    makePhotoOrder() {
      let data = new FormData();
      data.append("width", this.listData.width);
      data.append("height", this.listData.height);
      data.append("identificationPhotoId", this.listData.id);
      data.append("source", "h5");
      data.append("bgColor", this.activeList.data.filenames[0].bg_color);
      // data.append("fileSize", this.listData.fileSize);
      data.append("payWay", this.radio); //支付方式支付方式 0-免费 1-微信 2-支付宝 3-网银 4-余额

      makePhotoOrder(data).then((res) => {
        this.orderNo = res.data.orderNo;
        localStorage.setItem("orderNo", res.data.data.orderNo);
      });
    },
    init() {
      
      this.activeList = JSON.parse(localStorage.getItem("detail_makePhoto"));
      this.listData = JSON.parse(localStorage.getItem("shotTypeDetail"));
      this.makePhotoBase64 = localStorage.getItem("makePhotoBase64");
      if (this.activeList.data.filenames[0].bg_color === "#FFFFFF") {
        this.radio_1 = "white";
      } else if (this.activeList.data.filenames[0].bg_color === "#1989fa") {
        this.radio_1 = "blue";
      } else if (this.activeList.data.filenames[0].bg_color === "#ff0000") {
        this.radio_1 = "red";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.downMenuModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .dropdownMenu {
    position: fixed;
    right: 10px;
    top: 44px;
    background-color: #fff;
    z-index: 999;
    width: 100px;
    min-height: 200px;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);

    .menuItem {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      padding-left: 20px;
    }
    .menuItem5 {
      color: #ef8f27;
    }
  }
}
.codeID {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;

  .content-box {
    width: 100%;
    height: calc(100vh - 46px);
    margin: 46px auto 0;
    padding: 20px 0;

    .photo-box {
      width: 178px;
      margin: 0 auto;
      position: relative;

      img {
        display: block;
        width: 178px;
        height: 200px;
      }

      .title {
        position: absolute;
        top: 160px;
        left: 50px;
        opacity: 0.7;
        background: #8d8d8d;
        display: inline-block;
        width: 78px;
        color: #fff;
        padding: 2px 10px;
      }

      .van-radio-group {
        margin: 40px auto 20px;
        .van-row {
          width: 100%;
        }
        .van-radio {
          justify-content: center;
        }
      }
    }

    .type {
      margin: 20px auto;
      border-radius: 5px;
      width: calc(100% - 80px);
      background: #fff;
      padding: 10px 20px;

      .title {
        height: 24px;
        line-height: 24px;
        color: #ffb801;
      }

      .divider {
        width: 100%;
        height: 1px;
        background: #ebedf0;
      }
      .payment {
        margin: 10px 0;
        height: 24px;
        line-height: 24px;
      }
    }

    .desc {
      margin: 20px auto;
      border-radius: 5px;
      width: calc(100% - 80px);
      background: #fff;
      padding: 10px 20px;

      p {
        color: #ffb801;
      }
      p,
      div {
        height: 24px;
        line-height: 24px;
      }
      .activeBg {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        vertical-align: sub;
        border: 1px solid #e5e5e5;
      }
      .activeBgwhite {
        background: #fff;
      }
      .activeBgblue {
        background: #1989fa;
      }
      .activeBgred {
        background: #ff0000;
      }
    }

    .submit {
      background: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      width: calc(100% - 40px);
      height: 60px;
      box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.07);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #5f7dff;
      z-index: 2;
      padding: 0 20px;

      .title {
        color: #ff0000;
      }
      .baoMingBtn {
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #5f7dff;
        border-radius: 5px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
</style>
