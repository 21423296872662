import request from '@/axios/index';

//  生产证件照
export function makePhotoList(query) {
    return request({
        url: '/api/app/v1/idphoto/makePhoto',
        method: 'post',
        data: query
    })
}
//  获取课程协议
export function getphotoList() {
    return request({
        url: '/api/app/v1/idphoto/list',
        method: 'get',
    })
}

export function getMyIdentificationPhotos(pageNum,pageSize) {
    return request({
        url: '/api/app/v1/idphoto/order/getMyIdentificationPhotos?pageNum='+pageNum+"&pageSize="+pageSize,
        method: 'get',
    })
}

//  获取免费次数
export function getFreeDownloadNumber() {
    return request({
        url: '/api/app/v1/idphoto/order/getFreeDownloadNumber',
        method: 'get',
    })
}

//  证件照立即支付
export function h5Pay(query) {
    return request({
        url: '/api/app/v1/idphoto/order/h5Pay',
        method: 'POST',
        data: query
    })
}

//  微信支付
export function h5Paywx(query) {
    return request({
        url: '/api/app/v1/idphoto/order/wx/pay/callback',
        method: 'POST',
        data: query
    })
}

//  支付宝支付
export function h5Payzfb(query) {
    return request({
        url: '/api/app/v1/idphoto/order/zfb/pay/callback',
        method: 'POST',
        data: query
    })
}

//  生成证件照订单
export function makePhotoOrder(query) {
    return request({
        url: '/api/app/v1/idphoto/order/makePhotoOrder',
        method: 'POST',
        data: query
    })
}

//  下载证件照
export function downLoadFree(query) {
    return request({
        url: '/api/app/v1/idphoto/download/free',
        method: 'POST',
        data: query
    })
}


//  预览证件照
export function preview(previewImgName) {
    return request({
        url: '/api/app/v1/idphoto/preview?previewImgName='+previewImgName,
        method: 'get'
    })
}